// import pako from 'pako';

//origin string
export function zipEncode(data) {
    // const compressedData = pako.deflate(data, { to:'string' });
    const base64EncodedData = btoa(data);
    return base64EncodedData;
}

//encoded string
export function zipDecode(data) {
    const decodedData = atob(data);
    // 使用pako进行Gzip解压缩
    // const decompressedData = pako.inflate(decodedData, { to:'string' });
    return decodedData;
}
