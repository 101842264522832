<template>
    <div>
        <div class="SecondTitle">
            <span>{{ $t("common.welcome") }}</span>
        </div>

        <div v-show="showLineTCPPdiv">
            <div style="height: 48px; display: flex; justify-content: center;">
                <van-button class="line-btn" @click="gotoThirdPartAuth('line')">
                    <img class="line-icon" src="../../assets/img/line.svg"/>
                </van-button>
            </div>
            <div class="line-tips-div">
                <span class="line-tips">By clicking "Line", I agree to the Term Condition and Privacy Policy</span>
            </div>
            <div class="or-div">
                <div class="or-inner-div">
                    <div class="or-line"></div>
                    <span class="or-tips">OR</span>
                    <div class="or-line"></div>
                </div>
            </div>
        </div>
        
        <div class="tips-div">
            <div class="email-tips" v-html='$t("enterEmail.tips")'></div>
        </div>
        <van-cell-group class="form-cell" style="margin-top: 20px">
            <van-form>
                <div style="margin-top: 15px">
                    <van-field required :placeholder="this.$t('jpnRegistration.email')" v-model="email"
                        :error-message="errMessageEmail" @input="validatorEmail()" />
                </div>

                <van-divider :style="{ borderColor: '#000', margin: '0' }" />

                <van-button :disabled="check() || this.loading" size="large" round native-type="submit"
                    class="button-getotp" @click="searchCustomer()" :loading="loading">{{ $t("common.check")
                    }}</van-button>
            </van-form>
        </van-cell-group>

        <van-dialog v-model="showLimitDialog" :show-confirm-button="false">
            <template v-slot:title>
                <p style="text-align: left">{{ $t("enterOTP.limit") }}</p>
            </template>
            <template v-slot:default>
                <div>
                    <span>{{ $t("enterOTP.limitMsg1") }}</span><br>
                    <span>{{ $t("enterOTP.limitMsg2") }}</span>
                    <van-button style="width: 95%;margin-bottom: 10px" class="button-verify"
                        @click="closeLimitDialog()">
                        {{
                            $t("enterOTP.ok") }}
                    </van-button>
                </div>
            </template>
        </van-dialog>

    </div>
</template>

<script>
import {
    Cell,
    CellGroup,
    Form,
    DropdownMenu,
    DropdownItem,
    Divider,
    Button,
    Field,
    NumberKeyboard,
    PasswordInput,
    Toast,
    Dialog
} from "vant";
import { Image as VanImage } from "vant";
import { searchCustomer, getSession, generateOtp, getAuthorizeUrl } from "@/api/eformApi";
import mixins from "./mixin/Registration.js";
import { zipEncode } from "@/utils/zipcoder";
export default {
    name: "EnterEmail",
    mixins: [mixins],
    components: {
        [Cell.name]: Cell,
        [CellGroup.name]: CellGroup,
        [Form.name]: Form,
        [NumberKeyboard.name]: NumberKeyboard,
        [PasswordInput.name]: PasswordInput,
        [VanImage.name]: VanImage,
        [DropdownItem.name]: DropdownItem,
        [DropdownMenu.name]: DropdownMenu,
        [Divider.name]: Divider,
        [Button.name]: Button,
        [Field.name]: Field,
        [Dialog.Component.name]: Dialog.Component,
    },
    data() {
        return {
            email: "",
            loading: false,
            errMessageEmail: "",
            emailRegex: /^[0-9a-zA-Z-_.]*@[0-9a-zA-Z-_.]*[.][0-9a-zA-Z]{1,10}$/,
            showLimitDialog: false,
            showLineTCPPdiv: this.$route.params.countryPath == 'JPN' && this.brandConfig.enableLine && !this.$route.params.tpid,
            tpid: this.$route.params.tpid //授权绑定成功后返回tpid，传递给下一个路由对象
        };
    },
    created() {
        this.email = this.$route.params.email || "";
        console.log("EnterEmail", this)  
    },
    computed: {
        currentLocale() {
            return this.$root.$i18n.locale;
        },
    },
    watch: {
        currentLocale: {
            handler() {
                if (this.errMessageEmail != "") {
                    this.validatorEmail();
                }
            },
        },
    },
    methods: {
        validatorEmail() {
            this.errMessageEmail = "";
            if (!this.emailRegex.test(this.email.trim())) {
                this.errMessageEmail = this.$t("jpnRegistration.errMessageEmail");
                return false;
            }
            return true;
        },
        closeLimitDialog() {
            this.showLimitDialog = false;
        },
        searchCustomer() { 
            this.loading = true;
            getSession({
                country: this.$route.params.countryPath,
                email: this.email,
                brandCode: this.$route.params.brand
            }).then(res => {
                if (res.success) {
                    this.doSearchCustomer(res.data)
                } else {
                    Toast.fail({
                        type: "fail",
                        message: this.$t("enterEmail.toastFail"),
                    });
                    this.loading = false;
                }
            }).catch(() => {
                Toast.fail({
                    type: "fail",
                    message: this.$t("enterEmail.toastFail"),
                });
                this.loading = false;
            });
        },
        doSearchCustomer(sessionKey) {
            searchCustomer({
                country: this.$route.params.countryPath,
                brandCode: this.$route.params.brand,
                email: this.email,
                storeNo: this.$route.query.storeId,
                sessionKey: sessionKey,
            }).then((res) => {
                if (res.success) {
                    if (res.data.exist) {
                        Dialog.alert({
                            message: this.$t("jpnRegistration.alreadyRegistered"),
                            confirmButtonText: this.$t("common.confirm")
                        }).then(() => {
                            this.$router.push({
                                name: 'QRcodes',
                                params: {
                                    custId: res.data.data.consumerID,
                                    storeId: this.$route.query.storeId,
                                    lang: this.$route.params.lang,
                                    brand: this.$route.params.brand,
                                    country: this.$route.params.countryPath,
                                    localFirstName2: res.data.data.localFirstName2,
                                    localLastName2: res.data.data.localLastName2,
                                },
                                query: {
                                    storeId: this.$route.query.storeId,
                                }
                            })
                            this.loading = false
                        })
                    } else {
                        //暂时不需要OTP功能 TODO
                        // this.getOTP(sessionKey);

                        // todo start
                        Toast.success({
                             type: "success",
                             message: this.$t("enterEmail.toastSuccess")
                        });
                        this.$router.push(
                            {
                                name: 'Registration',
                                params: {
                                    lang: this.$route.params.lang,
                                    brand: this.$route.params.brand,
                                    country: this.$route.params.countryPath,
                                    sessionKey: sessionKey,
                                    email: this.email,
                                    tpid: this.tpid
                                },
                                query: {
                                    storeId: this.$route.query.storeId,
                                }
                            }
                        );
                        this.loading = false
                        // todo end
                         
                    }
                } else {
                    Toast.fail({
                        type: "fail",
                        message: this.$t("common.toastFail"),
                    });
                    this.loading = false;
                }
            }).catch(() => {
                Toast.fail({
                    type: "fail",
                    message: this.$t("common.toastFail"),
                });
                this.loading = false;
            });
        },
        getOTP(sessionKey) {
            generateOtp({
                email: this.email,
                country: this.$route.params.countryPath,
                brandCode: this.$route.params.brand,
                sessionKey: sessionKey
            })
                .then((res) => {
                    this.loading = false;
                    if (res.success) {
                        Toast.success({
                            type: "success",
                            message: this.$t("common.toastSuccess"),
                        });
                        this.$router.push(
                            {
                                name: 'EnterOpt',
                                params: {
                                    lang: this.$route.params.lang,
                                    brand: this.$route.params.brand,
                                    country: this.$route.params.countryPath,
                                    sessionKey: sessionKey,
                                    email: this.email,
                                    tpid: this.tpid
                                },
                                query: {
                                    storeId: this.$route.query.storeId,
                                }
                            }
                        );
                    } else if (res.data == -1) {
                        this.showLimitDialog = true
                    } else {
                        Toast.fail({
                            type: "fail",
                            message: this.$t("common.toastFail"),
                        });
                    }
                })
                .catch(() => {
                    Toast.fail({
                        type: "fail",
                        message: this.$t("common.toastFail"),
                    });
                    this.loading = false;
                });
        },
        check() {
            switch (this.$route.params.countryPath) {
                case "JPN":
                    return this.email.trim() == "" || this.errMessageEmail != ""
                default:
                    return true;
            }
        },
        gotoThirdPartAuth(thirdPart) {
            if (this.tpid) {
                console.log('tpid exist, skip bind')
                //skip if binded
                return
            }
            let country = this.$route.params.countryPath
            let brand = this.$route.params.brand
            this.$route.params.curLang = this.$root.$i18n.locale
            getAuthorizeUrl({
                thirdPart: thirdPart,
                country: country,
                brand: brand,
                hostPath: window.location.href.split('#')[0],
                nextRouter: zipEncode(JSON.stringify({
                    name: 'EnterEmail',
                    params: this.$route.params,
                    query: this.$route.query
                }))
            }).then((res) => {
                if (res.success) {
                    console.log(`${res.data.tpid} redirect to ${thirdPart} authorize url: ${res.data.authorizeUrl}`)
                    window.location.href = res.data.authorizeUrl
                } else {
                    console.log(res.errMsg)
                    Toast.fail({
                        type: "fail",
                        message: res.errMsg,
                    });
                }
            }).catch((err) => {
                console.log(err)
                Toast.fail({
                    type: "fail",
                    message: 'Authorize url get failed',
                });
            })
        }
    },
};
</script>

<style scoped>
* {
    font-family: Helvetica-Regular !important;
}

.form-cell >>> .van-hairline, .van-hairline--bottom, .van-hairline--left, .van-hairline--right, .van-hairline--surround, .van-hairline--top, .van-hairline--top-bottom{
    position: initial;
}
>>> .van-popup--top {
    width: 25%;
    padding-left: 16px;
}

.button-getotp {
    background: black;
    color: white;
    border-radius: 7px;
    margin-top: 4%;
}

>>> .van-password-input__item {
    border-radius: 5px;
}
.custom-class {
    color: #ff0000;
}

.phone_class >>> .van-field__control {
    border: 1px solid lightgray;
    border-radius: 5px;
    text-align: center;
}

>>>.van-dialog__header {
    padding-top: 0px;
    margin-left: 5%;
}

>>>.van-dialog__content {
    margin-left: 5%;
}

.button-verify {
    display: inline;
    background: black;
    color: white;
    border-radius: 7px;
    margin-top: 20px;
}

.line-tips-div{
    display: flex; 
    justify-content: center; 
    margin-top: 20px;
    margin-bottom: 10px;
}

.line-tips{
    /* width: 284px;
    height: 47px; */
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0px;
}

.line-btn{
    height: 48px; 
    width: 48px; 
    background-color: #00b900;
    border-radius: var(--iam-social-button-radius);
    border-top-left-radius: 50%;
    border-top-right-radius: 50%;
    border-bottom-right-radius: 50%;
    border-bottom-left-radius: 50%;
}

.line-icon{
    width: 24px; 
    height: 24px; 
    vertical-align: middle;
}

.tips-div{
    display: flex; 
    justify-content: center; 
    margin-top: 10px;
    margin-bottom: 10px;
}

.email-tips{
    /* width: 284px;
    height: 47px; */
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0px;
}

.or-div{
    display: flex; 
    justify-content: center; 
    height: 24px;
}

.or-inner-div{
    width: 100%; 
    border: 1px;
    display: flex;
    /** 使OR线水平居中 */
    justify-content: center;
    align-items: center;
}

.or-line{
    width: 60%;
    height: 0px;
    border-bottom: 1px solid #cccccc;
}

.or-tips{
    width: 24px;
    height: 24px;
    margin: 0px 15px;
    color: #cccccc;
}

</style>
<style>
.noticeWidth {
    width: 100%;
}
</style>
